import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { setSeoText } from 'src/app/utils/utils';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css', '../../../app.component.css'],
})
export class ContactUsComponent implements OnInit {

  constructor(private router: Router, @Inject(DOCUMENT) public _document: Document) { }

  ngOnInit() {
    setSeoText(this._document, 'AllTours - Contact Us for Support and Partnerships', 'Contact AllTours for any questions, feedback, or support. We are here to help you!');
  }
}
