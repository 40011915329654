<div class="row" style="padding-top: 32px; padding-bottom: 32px;">
  <div class="col-12 d-block d-lg-none">
    <p style="font-size: 20px; text-align: center; padding-bottom: 32px;">Need help?</p>
  </div>
  <div class="col-lg-6 col-12 d-none d-lg-block" style="margin-bottom: 32px">
    <h5>Need help? Have a question? Please get in touch.</h5>
    <p>We'd love to talk with you!</p>
  </div>
  <div class="col-lg-6 col-12" style="margin-bottom: 32px">
    <div style="display: flex">
      <img class="profile-pic" src="../../../assets/images/alltours/me.jpeg" alt="Profile picture" />
      <div style="margin-left: 16px">
        <h5>Kes Walker</h5>
        <p>Founder<br />AllTours (Explorer Innovations Ltd)</p>
        <div style="margin-left: -8px;">
          <a href="mailto:kes@alltours.app" style="margin: 4px"><button type="button" class="contactBtn">
              <span class="material-symbols-rounded smallIcon">mail</span> Send email
            </button></a>
          <button style="margin: 4px" *ngIf="showWhatsapp" type="button" class="contactBtn" data-bs-toggle="modal"
            data-bs-target="#whatsAppModal">
            <span class="material-symbols-rounded smallIcon">sms</span> Send WhatsApp
          </button>
          <a href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0tV2gnJ73aDDmmCjY6N_xCtTWtIGaCKLK1MYw9EAHZ2Rp-xGauIO_vrK4ad3nowRu9wKWmfIgo"
            style="margin: 4px" target="_blank"><button type="button" class="contactBtn">
              <span class="material-symbols-rounded smallIcon">call</span> Book call
            </button></a>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="whatsAppModal" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Send WhatsApp</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a">
        <h5 style="text-align: center; width: 100%; padding: 20%;">+43 678 9070076388</h5>
        <img src="../../../../assets/images/alltours/whatsapp.png" style="width: 100%" alt="WhatsApp icon" />
      </div>
    </div>
  </div>
</div>